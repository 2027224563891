import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { ApprovalTabsValEnum } from '@app/constants-enums/enums';

@Component({
	selector: 'app-notifications-tasks-popup',
	templateUrl: './notifications-tasks-popup.component.html',
	styleUrls: ['./notifications-tasks-popup.component.css']
})
export class NotificationsTasksPopupComponent implements OnInit {
	@Input() popupVisible: boolean = false;
	closeButtonOptions: any;
	okButtonOptions: any;
	positionOf: string;
	@ViewChild('notificationDataGrid') notificationDataGrid: DxDataGridComponent;
	public notificationDataSource: any;
	private getNotificationsSbsn: Subscription;
	@Output() getUnreadNotificationEvent = new EventEmitter<string>();
	public setNotificationRequestCall: any;
	public maxCreatedDateTime: any;
	public ApprovalTabsValEnum = ApprovalTabsValEnum;
	public isViewTaskNotification: boolean = false;
	public isViewOtherNotification: boolean = false;
	public notificationTypesLst: any[] = [
		{ name: 'Tasks', value: 'tasks', count: 0, isview: false },
		{ name: 'End-users Chargeback ', value: 'enduserschargebackcontracts', count: 0, tab: this.ApprovalTabsValEnum.APPROVALS_CHARGEBACKS, url: '/notifications/approvals/chargebacks', isview: true },
		{ name: 'Min-Max Chargeback', value: 'minmaxenduserschargebackcontracts', count: 0, tab: this.ApprovalTabsValEnum.APPROVALS_MIN_MAX_CHARGEBACKS, url: '/notifications/approvals/min_max_chargebacks', isview: true },
		{ name: 'Pricelist', value: 'pricelists', count: 0, tab: this.ApprovalTabsValEnum.APPROVALS_PRICELISTS, url: '/notifications/approvals/pricelist', isview: true },
		{ name: 'Rebates Settlements', value: 'rebatescontracts', count: 0, tab: this.ApprovalTabsValEnum.APPROVALS_REBATES_SETTLEMENTS, url: '/notifications/approvals/rebates_settlements', isview: true },
		{ name: 'Trade Promotion', value: 'tradepromotion', count: 0, tab: this.ApprovalTabsValEnum.APPROVALS_TRADE_PROMOTIONS, url: '/notifications/approvals/tradepromotion', isview: true },
	];

	constructor(private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _AppCommonSrvc: AppCommonSrvc,
		private _GlobalStateService: GlobalStateService,
		private _Router: Router,
		private _ToastrService: ToastrService) {
	}

	ngOnInit(): void {
		this.getNotificationDataSourceList();
		this.doHideShowNotificationTypeFromList();
		// if (this._LoginService.loginUser.account_detail['enable_shortpay_notification']) {
		this.callNotificationRequestForRealData();
		// }
	}
	ngOnDestroy() {
		if (this.getNotificationsSbsn) {
			this.getNotificationsSbsn.unsubscribe();
		}
		clearInterval(this.setNotificationRequestCall);
		this._GlobalStateService.unsubscribe('NOTIFICATION_REFRESHER_INTERVAL_EVENT');
	}

	doOnClosePopUp() {
		this.popupVisible = false;
	}

	doOnSeeNowPopUp() {
		this.popupVisible = false;
		//that.callNotificationRequestForRealData();
		this._AppCommonSrvc.setNotificationTab('notificationtasks');
		this._Router.navigate(['/notifications/taskslist']);
	}

	callNotificationRequestForRealData() {
		const isNotificationCall = this._AppCommonSrvc.getNotificationCall();
		if (isNotificationCall) {
			return false;
		}
		this._AppCommonSrvc.setNotificationCall(true);

		this.setNotificationRequestCall = setInterval(() => {
			this.getNotificationDataSourceList(true);
		}, 60000);
		this._GlobalStateService.subscribe('NOTIFICATION_REFRESHER_INTERVAL_EVENT', response => {
			if (response) {
				clearInterval(this.setNotificationRequestCall);
				this._AppCommonSrvc.setNotificationCall(false);
			}
		});
	}
	// getNotificationDataSourceList() {
	// 	const viewFields = ['guid', 'title', 'datetime', 'method', 'status', 'type', 'subtype', 'attachment'];
	// 	const searchArr = [{ 'type': 'task' }, { 'status': 'unread' }];
	// 	const loadParams = {
	// 		usr: this._LoginService.loginUser.user,
	// 		token: this._LoginService.loginUser.token,
	// 		method: ServerMethods.DX_GET_ENTITY_DATA,
	// 		entity: ServerEntity.NOTIFICATIONS,
	// 		view_fields: JSON.stringify(viewFields),
	// 	}
	// 	this.notificationDataSource = AspNetData.createStore({
	// 		key: "guid",
	// 		loadUrl: environment.URL_DATAOUT,
	// 		loadParams: loadParams,
	// 		onBeforeSend: function (method, ajaxOptions) {
	// 		},
	// 		onLoaded(result) {
	// 		}
	// 	});
	// }

	getNotificationDataSourceList(isNotificationCall?: boolean) {
		// const viewFields = ['guid', 'title', 'datetime', 'method', 'status', 'type', 'subtype', 'attachment', 'created_date'];
		// let searchParams: any[] = [['type', '=', 'Task'], 'and', ['status', '=', 'unread']];
		// let sortParams: any[] = [{ "selector": "created_date", "desc": true }];
		// if (this.maxCreatedDateTime) {
		// 	searchParams.push('and')
		// 	searchParams.push(['created_date', '>', this.maxCreatedDateTime])
		// }
		// const formData = new FormData();
		// formData.append('usr', this._LoginService.loginUser.user);
		// formData.append('token', this._LoginService.loginUser.token);
		// formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		// formData.append('entity', ServerEntity.NOTIFICATIONS);
		// formData.append('is_dropdown', 'true');
		// formData.append('filter', JSON.stringify(searchParams));
		// formData.append('sort', JSON.stringify(sortParams));
		// formData.append('view_fields', JSON.stringify(viewFields));

		let searchParams: any[] = [];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_NEW_PENDING_APPROVALS);
		if (this.maxCreatedDateTime) {
			formData.append('created_date', this.maxCreatedDateTime);
		}
		if (this.getNotificationsSbsn) {
			this.getNotificationsSbsn.unsubscribe();
		}
		this.getNotificationsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						this.notificationDataSource = response.data || [];
						//if (!response.data || response.data.length === 0) {
						//this.popupVisible = false;
						// this.getUnreadNotificationEvent.emit('0');
						//} else {
						setTimeout(() => {
							this.getNotificationMaxCreatedDateTime(response.data);
						});
						// if (isNotificationCall && !this.popupVisible && this.isGetNotification) {
						//if (isNotificationCall) {
						//this.popupVisible = true;
						//}
						//}
					}
				}
			},
			error: (error) => {
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}
	//get latest created notification list
	getNotificationMaxCreatedDateTime(notificationList: any[]) {
		// if (notificationList.length === 1) {
		// 	this.maxCreatedDateTime = notificationList[0]['created_date'] || '';
		// } else {
		// 	notificationList.forEach(element => {
		// 		if (element['created_date']) {
		// 			if (!this.maxCreatedDateTime) {
		// 				this.maxCreatedDateTime = element['created_date'];
		// 			} else {
		// 				if (element['created_date'] > this.maxCreatedDateTime) {
		// 					this.maxCreatedDateTime = element['created_date'];
		// 				}
		// 			}
		// 		}
		// 	});
		// }
		this.popupVisible = false;
		if (notificationList) {
			this.notificationTypesLst.forEach(n => {
				if (n.value) {
					if (notificationList.hasOwnProperty(n.value)) {
						let objLst: any[] = notificationList[n.value];
						if (objLst && objLst.length > 0) {
							n.count = objLst.length; // set notification count 
							if (!this.popupVisible) {
								this.popupVisible = true;
							}
							objLst.forEach(element => {
								if (element['created_date']) {
									if (!this.maxCreatedDateTime) {
										this.maxCreatedDateTime = element['created_date'];
									} else {
										if (element['created_date'] > this.maxCreatedDateTime) {
											this.maxCreatedDateTime = element['created_date'];
										}
									}
								}
							});
						}
					}
				}
			});
		}
		setTimeout(() => {
			this.showTaskNotification();
		});
		// console.log('maxCreatedDateTime', this.maxCreatedDateTime);
	}

	public showTaskNotification() {
		this.isViewTaskNotification = false;
		this.isViewOtherNotification = false;

		this.notificationTypesLst.forEach(element => {
			if (element.value === 'tasks') {
				if (element.count > 0) {
					this.isViewTaskNotification = true;
				}
			} else {
				if (element.count > 0) {
					this.isViewOtherNotification = true;
				}
			}
		});
	}
	public doOnNotificationTypeClick(nt) {
		this._AppCommonSrvc.setNotificationTab('notificationapprovals');
		this._AppCommonSrvc.setNotificationSubTab(nt.tab);
		this._Router.navigate([nt.url]);
		this.popupVisible = false;
	}

	// if feature is not enabled then Notification Type object removed from Array
	private doHideShowNotificationTypeFromList() {
		let isEnableRebateSettlementApprovalWorkflow = false;
		let isEnabledPricelistLifeCycle = false;
		let isEnabledShortPayNotification = false;
		let isEnabledMinMaxClaimNotification = false;
		let isEnabledTpmApprovalWorkflow = false;
		try {
			isEnableRebateSettlementApprovalWorkflow = this._LoginService.loginUser.account_detail.enable_rebate_settlement_approval_workflow;
			isEnabledPricelistLifeCycle = this._LoginService.loginUser.account_detail.enable_pricelist_approval_workflow;
			isEnabledShortPayNotification = this._LoginService.loginUser.account_detail.enable_shortpay_notification;
			isEnabledMinMaxClaimNotification = this._LoginService.loginUser.account_detail.enable_min_max_claim;
			isEnabledTpmApprovalWorkflow = this._LoginService.loginUser.account_detail.enable_tpm_approval_workflow;
		} catch (e) {
			isEnableRebateSettlementApprovalWorkflow = false;
			isEnabledPricelistLifeCycle = false;
			isEnabledShortPayNotification = false;
			isEnabledTpmApprovalWorkflow = false;
		}

		if (!isEnabledShortPayNotification) {
			const taskIndex = this.notificationTypesLst.findIndex(nt => nt.value === 'tasks');
			if (taskIndex !== -1) {
				this.notificationTypesLst.splice(taskIndex, 1);
			}
		}
		if (!isEnabledPricelistLifeCycle) {
			const priceListsIndex = this.notificationTypesLst.findIndex(nt => nt.value === 'pricelists');
			if (priceListsIndex !== -1) {
				this.notificationTypesLst.splice(priceListsIndex, 1);
			}
		}
		if (!isEnableRebateSettlementApprovalWorkflow) {
			const rebateContractIndex = this.notificationTypesLst.findIndex(nt => nt.value === 'rebatescontracts');
			if (rebateContractIndex !== -1) {
				this.notificationTypesLst.splice(rebateContractIndex, 1);
			}
		}
		if (!isEnabledMinMaxClaimNotification) {
			const minmaxEndUsersChargebackIndex = this.notificationTypesLst.findIndex(nt => nt.value === 'minmaxenduserschargebackcontracts');
			if (minmaxEndUsersChargebackIndex !== -1) {
				this.notificationTypesLst.splice(minmaxEndUsersChargebackIndex, 1);
			}
		}
		if (!isEnabledTpmApprovalWorkflow) {
			const tpmApprovalWorkflowIndex = this.notificationTypesLst.findIndex(nt => nt.value === 'tradepromotion');
			if (tpmApprovalWorkflowIndex !== -1) {
				this.notificationTypesLst.splice(tpmApprovalWorkflowIndex, 1);
			}
		}
	}
}

