import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { PopUpCallFromEnum, PopupHeightWidth } from '@app/constants-enums/enums';
import { AddApprovalUIModel } from '@app/models/application-configuration.model';
import { PageOptions } from '@app/models/common.model';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { DataService } from '@app/services/data.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Observable, Subject, Subscription } from 'rxjs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { EndUsersChargebackContractService } from '@app/services/contract-service/billback/end-users-chargeback-contract.service';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { ViewChargebackDocPropsModel } from '@app/models/end-users-chargeback-contract.model';

@Component({
	selector: 'app-notifications-approvals-tab',
	templateUrl: './notifications-approvals-tab.component.html',
	styleUrls: ['./notifications-approvals-tab.component.css']
})
export class NotificationsApprovalsTabComponent implements OnInit {
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	public approvalsDataSource: any = {};
	public pageOptions: PageOptions = new PageOptions();
	public guid = new Guid();
	public listOfStatuses: any[] = [];
	public listOfApproveStatuses: any[] = [];
	public listOfHeaderFilterStatuses: any[] = [];
	private dataInReqSubscription: Subscription;
	private getApprovalListSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	private getChargebackDocumentListSubscription: Subscription;
	private updateUserSettingSbsn: Subscription;
	public addApprovalUI = new AddApprovalUIModel();
	public approvalPopUpTitle: string;
	public isViewApprovalPopUp: boolean = false;
	public globalCurrency: string;
	//claim-pop-up
	public chargeBackDocumentDetails: any;
	public totalClaimed: number;
	public totalClaimedAmount: number;
	public downloadDocumentURL: string;
	public claimDocumentURL: string;
	public outputDocumentURL: string;
	public approvalGuid: string;
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public uomListData: any = [];
	public shortPayTaskDataSource: any;
	private isCellClickDetail: boolean = false;
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public verifyCsvDocumentSbsn: Subscription;
	@ViewChild("endUserChargeBacDocumentDataGridContainer", {}) endUserChargeBacDocumentDataGridContainer: DxDataGridComponent;
	public filterValue: any[] = [];
	public commissionContractDataSource: any = {};
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public storedFiltersValue: any;
	public stateStorageKey: string;
	public mainSectionStorageKey: string;
	public is_enable_min_max_claim: boolean = false;
	public decimalPointForCurrency: number;
	public listOfActions: any[] = [];
	public isViewChargebackDocPopUp: boolean;
	public viewChargebackDocProps: ViewChargebackDocPropsModel;
	constructor(private _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _ToastrService: ToastrService,
		private _BillbackService: BillbackService,
		private _EndUsersChargebackContractService: EndUsersChargebackContractService,
		private _IxDxAlertService: IxDxAlertService,
		private _DataService: DataService,
	) {
		this.doLoadApproveNotificationState = this.doLoadApproveNotificationState.bind(this);
		this.doSaveApproveNotificationState = this.doSaveApproveNotificationState.bind(this);
	}

	ngOnInit(): void {
		this.listOfStatuses = this._AppCommonSrvc.doGetListofApprovalStatus();
		this.listOfApproveStatuses = this._EndUsersChargebackContractService.doGetListofApprovalStatus();
		this.listOfHeaderFilterStatuses = this._AppCommonSrvc.doGetListofApprovalStatusForHeaderFilter();
		this.listOfActions = this._EndUsersChargebackContractService.getListOfActionsForApprovalChargebackDatagrid;
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;
		this.globalCurrency = this._LoginService.globalCurrency;
		this.stateStorageKey = ServerSections.CLAIM_APPROVE_NOTIFICATIONS;
		this.mainSectionStorageKey = ServerSections.NOTIFICATIONS;
		this.storedFiltersValue = [
			{ dataType: 'string', dataField: 'claimedStatus', searchMode: '=', filterValues: ['inprocess'] }
		];
		this.applyStoredFilter();
		this.getApprovalList();
		//claim-popup
		this.globalCurrency = this._LoginService.globalCurrency;
		this.uomListData = this._DataService.getUOMs();
		this.totalClaimed = 0;
		this.totalClaimedAmount = 0;

		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.isQBErpUser = this._BillbackService.getQbErpUser();
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail['enable_min_max_claim']) {
			this.is_enable_min_max_claim = true;
		}
		this.preInIt();
	}

	public preInIt() {
		setTimeout(() => {
			if (this.dataGridContainer && this.dataGridContainer['filterValueChange']) {
				this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
				this.filterValueChangeSbsn.subscribe((result) => {
					this.getFilterText();
				});
			}
		}, 60);
	}

	ngOnDestroy() {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.getApprovalListSubscription) {
			this.getApprovalListSubscription.unsubscribe();
		}
		if (this.getChargebackDocumentListSubscription) {
			this.getChargebackDocumentListSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.verifyCsvDocumentSbsn) {
			this.verifyCsvDocumentSbsn.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
			this.updateStatesClearBtn();
		};
		this.updateStatesClearBtn();
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					icon: "cleariconix",
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.filterText = '';
						this.isDisabledClearBtn = true;
						this.updateStatesClearBtn();
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			}
		);

		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
			columnChooserButton.visible = false;
			columnChooserButton.icon = 'columnChooser';
		}
	}

	public doActionsBtnClick(event: any) {
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.doShowColumnChooserDatagrid();
					break;
			}
		}
	}

	private doShowColumnChooserDatagrid() {
		this.dataGridContainer.instance.showColumnChooser();
	}

	private doRefreshDataGrid() {
		this.dataGridContainer.instance.refresh();
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = 'Claim_Approve_List.xlsx';
		worksheet = workbook.addWorksheet('Claim Approve List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'claimedStatus') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = gridCell.value == 'approve' ? 'APPROVED' : gridCell.value == 'DECLINE' ? 'DECLINED' : "INPROCESS";
						colorName = gridCell.value ? '05c1a0' : 'f99300';
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	//#endregion

	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this.storedFiltersValue;
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						if (sf.filterValues) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
						}
					}
				});
			}, 75);
		}

	}

	private getApprovalList() {
		const reqFormData = new FormData();
		// const searchParams = [['type', '=', 'approval']];
		// reqFormData.append('usr', this._LoginService.loginUser.user);
		// reqFormData.append('token', this._LoginService.loginUser.token);
		// reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		// reqFormData.append('entity', ServerEntity.NOTIFICATIONS);
		// reqFormData.append('filter', JSON.stringify(searchParams));
		// if (this.getApprovalListSubscription) {
		// 	this.getApprovalListSubscription.unsubscribe();
		// }
		// this._LoaderService.show();
		// this.getApprovalListSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
		// 	next: (response) => {
		// 		this._LoaderService.hide();
		// 		if (response && response.flag) {
		// 			this.approvalsDataSource = response.data || [];
		// 		} else {
		// 			this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
		// 		}
		// 	},
		// 	error: (error) => {
		// 		this._LoaderService.hide();
		// 		this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		// 	}
		// });
		const searchParams = [['type', '=', 'approval'], 'and', ['claimedStatus', '=', 'inprocess']];
		// this.filterValue = [['type', '=', 'approval'], 'and', ['claimedStatus', '=', 'inprocess']];

		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.NOTIFICATIONS,
			type: 'approval',
			// filter: JSON.stringify(searchParams),
		};
		this.approvalsDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	public deleteApproveListItem(objItem) {
		this._IxDxAlertService.doShowDeleteConfirmAlert().then(result => {
			if (result) {
				this.doOnRowRemovedApprovalTab(objItem);
			}
		});
	}

	public doOnRowRemovedApprovalTab(objItem) {
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('action', 'delete');
		formdata.append('is_delete_approval', 'true');
		formdata.append(ServerEntity.NOTIFICATIONS, JSON.stringify([objItem.guid]));
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formdata).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					let msg = 'Recorded Removed successfully';
					this._ToastrService.success(response.message || msg, 'Success', { closeButton: true, tapToDismiss: true });
					this.getApprovalList();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					this._LoaderService.hide();
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private getChargebackDocumentList(data: any) {
		const reqFormData = new FormData();
		const searchParams = [['guid', '=', data['claim_guid']]];
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		reqFormData.append('entity', ServerEntity.CHARGEBACKDOC);
		reqFormData.append('filter', JSON.stringify(searchParams));
		if (this.getChargebackDocumentListSubscription) {
			this.getChargebackDocumentListSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.getChargebackDocumentListSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response && response.flag) {
					if (response.data && response.data.length > 0) {
						this.chargeBackDocumentDetails = response.data[0];
						this.chargeBackDocumentDetails.claimedStatus = data['claimedStatus'] || '';
						this.shortPayTaskDataSource = response.data[0].shortPayDetails || [];
						this.loadComponent();
						// this.isViewApprovalPopUp = true;
						if (this.chargeBackDocumentDetails && this.chargeBackDocumentDetails.hasOwnProperty('contractGuid') && this.chargeBackDocumentDetails.contractGuid) {
							this._EndUsersChargebackContractService.getEndUserDetail(this.chargeBackDocumentDetails.contractGuid).subscribe({
								next: (response) => {
									this._LoaderService.hide();
									if (response.flag) {
										let responseData = response.data[0];
										if (responseData && !responseData.hasOwnProperty('is_enforce_quantity_sold')) {
											responseData['is_enforce_quantity_sold'] = true;
										}
										const objEndUsersChargebackContract = responseData;
										//this.viewChargebackDocPopUp(objEndUsersChargebackContract, this.chargeBackDocumentDetails, data);
									} else {
										this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
									}
								}, error: (error) => {
									this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
									this._LoaderService.hide();
								}
							});
						}

					} else {
						this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// public viewChargebackDocPopUp(data) {
	// 	const dialogData = {
	// 		claimId: data.ixcode,
	// 		notificationGuid: data.guid,
	// 		callFrom: PopUpCallFromEnum.NOTIFICATIONS,
	// 	}
	// 	this.loadViewChargebackDocDxDialogSubject.next(dialogData);

	// 	this.approvalPopUpTitle = data.claim_id + ' - ' + data.contract_name;
	// 	this.approvalGuid = objEndUsersChargebackContract.guid;
	// }

	public viewChargebackDocPopUp(data) {
		const viewChargebackDocProps = new ViewChargebackDocPropsModel();
		viewChargebackDocProps.claimGuid = data.claim_guid;
		viewChargebackDocProps.callFrom = PopUpCallFromEnum.NOTIFICATIONS;
		viewChargebackDocProps.notificationGuid = data.guid;
		this.viewChargebackDocProps = viewChargebackDocProps;
		this.isViewChargebackDocPopUp = false;
		setTimeout(() => {
			this.isViewChargebackDocPopUp = true;
		}, 50);
		this.approvalPopUpTitle = data.claim_id + ' - ' + data.contract_name;
		this.approvalGuid = data.contract_id;
	}

	public getListOfStatus(data) {
		this.listOfStatuses.forEach(action => {
			action.label = action.label.toUpperCase();
		});
		this.listOfStatuses.forEach(action => {
			if (action.value === data.claimedStatus) {
				action.visible = false;
			} else {
				action.visible = true;
			}
		});
	}

	public getClassNameForStatusField(status) {
		const gotStatusIndex = this.listOfStatuses.findIndex(s => s.value === status);
		let dynamicClass = 'dynamic_21b3c6';
		if (gotStatusIndex !== -1) {
			const statusObj = JSON.parse(JSON.stringify(this.listOfStatuses[gotStatusIndex]));
			if (statusObj.bgcolor) {
				dynamicClass = 'dynamic_' + statusObj.bgcolor.toString().replace('#', '');
			}
		}
		return dynamicClass;
	}

	public viewApprovalsPopup(data) {
		if (this.isCellClickDetail) {
			return;
		}
		//this.getChargebackDocumentList(data);
		this.viewChargebackDocPopUp(data);

	}

	doclickRefrenceDocument() {
		window.open(this.downloadDocumentURL);
	}

	doClickClaimedDocument() {
		window.open(this.claimDocumentURL);
	}

	doClickOutputDocument() {
		const formData = new FormData();
		let docId = '';
		if (this.chargeBackDocumentDetails['csvFileURL'] && this.chargeBackDocumentDetails['csvFileURL'].length > 0) {
			docId = this.chargeBackDocumentDetails.csvFileURL;
		} else {
			docId = this.chargeBackDocumentDetails['guid'];
		}
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('docid', docId);
		formData.append('doctype', 'csv');
		formData.append('verify', 'true');
		this._LoaderService.show();
		if (this.verifyCsvDocumentSbsn) {
			this.verifyCsvDocumentSbsn.unsubscribe();
		}
		this.verifyCsvDocumentSbsn = this._RestApiService.doGetCsvDocumentReq(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						window.open(this.outputDocumentURL);
					} else {
						this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public loadComponent() {
		if (this.chargeBackDocumentDetails.documentURL) {
			const documentURL = this.chargeBackDocumentDetails.documentURL;
			const documentNewURLArr = documentURL.split('-');
			if (documentNewURLArr.length > 0) {
				const docId = documentNewURLArr[0];
				const docDate = documentNewURLArr[1];
				const docCode = documentNewURLArr[2];
				const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=chargebackDoc'
				this.downloadDocumentURL = finalUrl;
			}
		}
		if (this.chargeBackDocumentDetails.claimDocumentURL) {
			const documentURL = this.chargeBackDocumentDetails.claimDocumentURL;
			const documentNewURLArr = documentURL.split('-');
			if (documentNewURLArr.length > 0) {
				const docId = documentNewURLArr[0];
				const docDate = documentNewURLArr[1];
				const docCode = documentNewURLArr[2];
				const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=chargebackClaimDoc'
				this.claimDocumentURL = finalUrl;
			}
		}
		if (this.chargeBackDocumentDetails['csvFileURL']) {
			const documentURL = this.chargeBackDocumentDetails.csvFileURL;
			if (documentURL.length > 0) {
				const docId = documentURL;
				const finalUrl = environment.URL_SERVER + '/getCsvDoc.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&doctype=csv'
				this.outputDocumentURL = finalUrl;
			}
		} else {
			const docId = this.chargeBackDocumentDetails['guid'];
			const finalUrl = environment.URL_SERVER + '/getCsvDoc.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&doctype=csv'
			this.outputDocumentURL = finalUrl;
		}

		for (let i = 0; i < this.chargeBackDocumentDetails.claimedItems.length; i++) {
			const objClaimed = parseFloat(this.chargeBackDocumentDetails.claimedItems[i].claimed) || 0;
			const objClaimedAmount = parseFloat(this.chargeBackDocumentDetails.claimedItems[i].linetotal) || 0;
			this.totalClaimed = this.totalClaimed + objClaimed;
			this.totalClaimedAmount = this.totalClaimedAmount + objClaimedAmount;
		}
	}

	changedApprovalStatusAction(event, approvalGuid) {
		this._LoaderService.show();
		const objUpdateData = { 'claimedStatus': event.itemData.value };
		const searchParams = [['type', '=', 'approval']];
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.UPDATE_ENTITY_DATA);
		reqFormData.append('guid', approvalGuid);
		reqFormData.append('updateData', JSON.stringify(objUpdateData));
		reqFormData.append('entity', ServerEntity.NOTIFICATIONS);
		reqFormData.append('filter', JSON.stringify(searchParams));
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataInFormDataReq(reqFormData).subscribe({
			next: (response) => {
				if (response.flag) {
					this._LoaderService.hide();
					this.getApprovalList();
				} else {
					this._LoaderService.hide();
					this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
				}
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to prevent rowclick for particular column click
	public doCellClickDetails(e) {
		this.isCellClickDetail = false;
		if (e.column.dataField === "claimedStatus") {
			this.isCellClickDetail = true;
			return;
		}
	}

	//#region load state and save state
	public doLoadApproveNotificationState() {
		this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				//this.filterValue = newOrExistingConfigClone.configs[configIndex].state_persistent['filterValue'];
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveApproveNotificationState(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}
	//#endregion

	public doHideViewClaimDxDialog(result) {
		if (result) {
			this.doRefreshDataGrid();
		}
	}
}
