<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="approvalsDataSource" [showBorders]="true"
	[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true" [columnResizingMode]="'nextColumn'"
	[allowColumnReordering]="true" [columnAutoWidth]="true" [filterSyncEnabled]="true"
	[remoteOperations]="{ groupPaging: true }" [dateSerializationFormat]="'yyyy-MM-dd'" [columnAutoWidth]="true"
	[filterValue]="filterValue" (onRowClick)="doViewClaimApprovalsPopup($event.data);" [width]="'100%'"
	(onToolbarPreparing)="doToolbarPreparing($event)" class="cellColor hoverClr hideFilterPanelInx">
	<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true"> </dxo-pager>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="true"></dxo-filter-panel>
	<dxo-header-filter [visible]="true"></dxo-header-filter>
	<dxo-sorting mode="single"></dxo-sorting>

	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadApproveNotificationState"
		[customSave]="doSaveApproveNotificationState" [storageKey]="stateStorageKey">
	</dxo-state-storing>


	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>

	<dxi-column dataField="claim_id" caption="Claim Id" dataType="string" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateClaimId">
		<div *dxTemplate="let element of 'cellTemplateClaimId'">
			{{ element.data.claim_id}}
		</div>
	</dxi-column>
	<dxi-column caption="Claim No." [allowHiding]="false" dataField="claimsList" dataType="string" [allowEditing]="false"
		[allowFiltering]="true" [allowHeaderFiltering]="false" cellTemplate="cellTemplateClaimNo">
		<div *dxTemplate="let element of 'cellTemplateClaimNo'">
			<span>
				{{element.data.claimsList && element.data.claimsList.length > 0 ?
				element.data.claimsList[0] : ''}}
				<span [attr.id]="'claimNoToolTip_' + element.data.guid"
					*ngIf="element.data.claimsList[0] && (element.data.claimsList.length === 2 || element.data.claimsList.length >= 3)">
					+ {{element.data.claimsList.length-1}}
					{{ element.data.claimsList.length === 2 ? 'Other' : element.data.claimsList.length >= 3 ?
					'Others' : ''}}
				</span>
				<dx-tooltip target="#claimNoToolTip_{{element.data.guid}}" showEvent="dxhoverstart" hideEvent="dxhoverend"
					position="right">
					<div *dxTemplate="let d = d; of: 'content'">
						<div class="tooltip-wrapper">
							<div class="greenTipout">
								<ol class="tipList">
									<li *ngFor="let claim of element.data.claimsList; let p = index;">
										<a>{{claim || ''}}</a>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</dx-tooltip>
			</span>
		</div>
	</dxi-column>

	<dxi-column caption="Customer Name" [allowHiding]="false" dataField="customersList" dataType="string"
		[allowEditing]="false" [allowFiltering]="true" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateCustomerName">
		<div *dxTemplate="let element of 'cellTemplateCustomerName'">
			<span>
				{{element.data.customersList && element.data.customersList.length > 0 ?
				element.data.customersList[0].customer_name : ''}}
				<span [attr.id]="'customerToolTip_' + element.data.guid"
					*ngIf="element.data.customersList[0] && (element.data.customersList.length === 2 || element.data.customersList.length >= 3)">
					+ {{element.data.customersList.length-1}}
					{{ element.data.customersList.length === 2 ? 'Other' :
					element.data.customersList.length >= 3 ?
					'Others' : ''}}
				</span>
				<dx-tooltip target="#customerToolTip_{{element.data.guid}}" showEvent="dxhoverstart" hideEvent="dxhoverend"
					position="right">
					<div *dxTemplate="let d = d; of: 'content'">
						<div class="tooltip-wrapper">
							<div class="greenTipout">
								<ol class="tipList">
									<li *ngFor="let customer of element.data.customersList; let p = index;">
										<a>{{customer.customer_name || ''}}</a>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</dx-tooltip>
			</span>
		</div>
	</dxi-column>

	<dxi-column dataField="type" caption="Type" dataType="string" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateType" [visible]="false" [showInColumnChooser]="false">
		<div *dxTemplate="let element of 'cellTemplateType'">
			{{ element.data.type}}
		</div>
	</dxi-column>

	<dxi-column dataField="contract_name" caption="Contract Name" dataType="string" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateContractmName">
		<div *dxTemplate="let element of 'cellTemplateContractmName'">
			{{ element.data.contract_name}}
		</div>
	</dxi-column>

	<dxi-column dataField="selectCustomerId" caption="Customer Code" dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>

	<dxi-column dataField="created_by_name" caption="Created By" dataType="string" [allowHeaderFiltering]="true"
		cellTemplate="cellTemplateCreatedBy" [allowFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateCreatedBy'">
			{{element.data.created_by_name}}
		</div>
	</dxi-column>

	<dxi-column dataField="created_date" caption="Document Date" dataType="date" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateDocDate">
		<div *dxTemplate="let element of 'cellTemplateDocDate'">
			{{ (element.data.created_date !== null && element.data.created_date !== '' &&
			element.data.created_date
			!== undefined && element.data.created_date !== 'Invalid date') ? (element.data.created_date | date:
			'yyyy-MM-dd') : "" }}
		</div>
	</dxi-column>
	<dxi-column dataField="claim_date" caption="Claim Date" dataType="date" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateClaimDate">
		<div *dxTemplate="let element of 'cellTemplateClaimDate'">
			{{ (element.data.claim_date !== null && element.data.claim_date !== '' &&
			element.data.claim_date
			!== undefined && element.data.claim_date !== 'Invalid date') ? (element.data.claim_date | date:
			'yyyy-MM-dd') : "" }}
		</div>
	</dxi-column>

	<dxi-column dataField="claimed_items_count" caption="Claimed Item Line(s)" dataType="number" [allowFiltering]="false"
		[allowFiltering]="false" [allowHeaderFiltering]="false" cellTemplate="cellTemplateClaimedItemCount">
		<div *dxTemplate="let element of 'cellTemplateClaimedItemCount'">
			{{element.data.claimed_items_count || 0}}
		</div>
	</dxi-column>

	<dxi-column dataField="claim_amount" caption="Approved Amount" dataType="number" [allowHeaderFiltering]="false"
		[allowFiltering]="false">
		<dxo-format type="currency" [precision]="decimalPointForCurrency">
		</dxo-format>
	</dxi-column>

	<dxi-column dataField="user_claim_amount_total" caption="Claimed Amount" dataType="number"
		[allowHeaderFiltering]="false" [allowFiltering]="false">
		<dxo-format type="currency" [precision]="decimalPointForCurrency">
		</dxo-format>
	</dxi-column>

	<dxi-column dataField="approval_status" caption="Approval Status" dataType="string" [allowFiltering]="false"
		[allowHeaderFiltering]="true" cellTemplate="cellTemplateApprovalStatus">
		<dxo-header-filter [dataSource]="listOfApprovalStatuses"></dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateApprovalStatus'">
			<div
				[ngClass]="element.data.approval_status || '' | filterFromList:listOfApprovalStatuses : 'value' : 'classname'">
				{{ element.data.approval_status || '' | filterFromList:listOfApprovalStatuses:'value':'text'}}
			</div>
		</div>
	</dxi-column>
	<dxi-column caption="Approval Amount" dataField="approvalAmount" alignment="right" dataType="number"
		[allowHeaderFiltering]="false" [allowFiltering]="false">
		<dxo-format type="currency" [precision]="decimalPointForCurrency">
		</dxo-format>
	</dxi-column>

	<dxi-column type="buttons" caption="Action" [fixed]="true" fixedPosition="right" [allowFiltering]="false"
		[allowHeaderFiltering]="false" [allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150"
		[width]="150" [allowExporting]="false" cellTemplate="cellTemplateAction">
		<div *dxTemplate="let element of 'cellTemplateAction'" class="center actBtn">
			<a (click)="doViewClaimApprovalsPopup(element.data)">
				<span class="icon-view icon-view_action-col"></span>
			</a>
		</div>
	</dxi-column>

</dx-data-grid>

<app-trade-promotion-view-claim-details-dialog *ngIf="isShowViewClaimDetailsPopup"
	[claimDetailsProps]="viewClaimDetailsProps"
	(closeViewClaimDetailsPopup)="doCloseViewClaimDetailsPopup($event)"></app-trade-promotion-view-claim-details-dialog>