<div class="borderBox mrgB0 ">
	<div class="tabbing cf">
		<ul>
			<li (click)="doClickOnTab(ApprovalTabsValEnum.APPROVALS_CHARGEBACKS)"
				[ngClass]="{active: currentTab === ApprovalTabsValEnum.APPROVALS_CHARGEBACKS}">
				<a>Chargebacks<span *ngIf="pendingApprovalCount.endUsersChargeback > 0"
						class="notificationAlert"></span></a>
			</li>
			<li *ngIf="isEnabledPricelistApprovalWorkflow" (click)="doClickOnTab(ApprovalTabsValEnum.APPROVALS_PRICELISTS)"
				[ngClass]="{active: currentTab === ApprovalTabsValEnum.APPROVALS_PRICELISTS}">
				<a>Price List<span *ngIf="pendingApprovalCount.pricelist > 0" class="notificationAlert"></span></a>
			</li>
			<li *ngIf="isEnabledRebateSettlementApprovalWorkflow"
				(click)="doClickOnTab(ApprovalTabsValEnum.APPROVALS_REBATES_SETTLEMENTS)"
				[ngClass]="{active: currentTab === ApprovalTabsValEnum.APPROVALS_REBATES_SETTLEMENTS}">
				<a>Rebate Settlements<span *ngIf="pendingApprovalCount.rebate > 0" class="notificationAlert"></span></a>
			</li>
			<li *ngIf="isEnabledMinMaxClaimFeature"
				(click)="doClickOnTab(ApprovalTabsValEnum.APPROVALS_MIN_MAX_CHARGEBACKS)"
				[ngClass]="{active: currentTab === ApprovalTabsValEnum.APPROVALS_MIN_MAX_CHARGEBACKS}">
				<a>Min-Max Chargebacks<span *ngIf="pendingApprovalCount.minMaxEndUsersChargeback > 0"
						class="notificationAlert"></span></a>
			</li>
			<li *ngIf="isEnabledTpmApprovalWorkflow" (click)="doClickOnTab(ApprovalTabsValEnum.APPROVALS_TRADE_PROMOTIONS)"
				[ngClass]="{active: currentTab === ApprovalTabsValEnum.APPROVALS_TRADE_PROMOTIONS}">
				<a>Trade Promotion<span *ngIf="pendingApprovalCount.minMaxEndUsersChargeback > 0"
						class="notificationAlert"></span></a>
			</li>
		</ul>
	</div>
	<div class="mrgB0 cf">
		<div class="tableOut">
			<app-notifications-approvals-tab
				*ngIf="currentTab==='approvals_chargebacks'"></app-notifications-approvals-tab>
			<app-pricelist-approvals-listing *ngIf="currentTab==='approvals_pricelists'"></app-pricelist-approvals-listing>
			<app-rebate-settlements-approvals-listing
				*ngIf="currentTab==='approvals_rebates_settlements'"></app-rebate-settlements-approvals-listing>
			<!-- <router-outlet></router-outlet> -->
			<app-min-max-chargeback-claim-approvals-listing
				*ngIf="currentTab===ApprovalTabsValEnum.APPROVALS_MIN_MAX_CHARGEBACKS">
			</app-min-max-chargeback-claim-approvals-listing>
			<app-trade-promotions-approvals-listing *ngIf="currentTab===ApprovalTabsValEnum.APPROVALS_TRADE_PROMOTIONS">
			</app-trade-promotions-approvals-listing>
		</div>
	</div>
</div>